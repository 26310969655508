import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/styles';
import logo from 'dataModule/assets/img/HealthBeatsDialogLogo.svg';
import { ERROR_MESSAGES } from '../constants';

const styles = (theme) => ({
  dialogPaper: {
    minHeight: '256px',
    minWidth: '654px',
    borderRadius: '5px',
  },
  topHeader: {
    margin: 0,
    padding: 2,
    height: 64,
    backgroundColor: '#fff',
  },
  primaryColor: {
    color: '#ff5100',
  },
  listItem: {
    height: 'auto !important',
  },
  dialogLogo: {
    maxWidth: '40px',
  },
  dialogTitle: {
    color: '#212121',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '19px',
    letterSpacing: '0.15000000596046448px',
  },
  dialogCloseButton: {
    position: 'relative',
    left: '10px',
    color: 'black',
    maxHeight: '40px',
    maxWidth: '40px',
  },
  dialogDisplayFlex: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: '40px',
    textIndent: 0,
  },
  dialogListItem: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '19px',
    color: '#212121',
    letterSpacing: '0.5px',
  },
  link: {
    marginLeft: 30,
  },
  icon: {
    marginRight: 8,
  },
  button: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '1.25px',
    textAlign: 'center',
    minWidth: 161,
  },
  tabRoot: {
    [theme.breakpoints.up('md')]: {
      minWidth: 80,
    },
    backgroundColor: '#fff',
    opacity: 'unset',
    height: 60,
    '&:hover': {
      color: '#2979ff',
      opacity: 1,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  tabLabelContainer: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
    },
  },
  label: {
    textTransform: 'capitalize',
    color: '#212121',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: 1.2,
    fontStretch: 'normal',
    letterSpacing: '0.5px',
    '&:hover': {
      color: '#212121',
    },
  },
  indicator: { backgroundColor: '#ff5100' },
});

@withStyles(styles, { index: 1 })
export default class DialogProgramValidation extends React.PureComponent {
  handleClose = async () => {
    this.props.toggleCloseDialog();
  };

  render() {
    const {
      open,
      classes: { dialogPaper, dialogLogo, dialogDisplayFlex, dialogTitle, dialogCloseButton },
      error,
      proceed,
    } = this.props;

    const message = ERROR_MESSAGES.find((e) => e.key === error);
    const title = message && message.title;
    // eslint-disable-next-line react/jsx-key
    const description = message && message.description.split('\n').map((str) => <div style={{ color: str === '<br>' ? '#FFFFFF' : null }}>{str}</div>);
    const submitLabel = message && message.submitLabel;
    const cancelLabel = message && message.cancelLabel;

    return (
      <Dialog open={open} classes={{ paper: dialogPaper }} aria-labelledby="ihealth-customer-care-info">
        <DialogTitle id="ihealth-customer-care-info" style={{ padding: '12px 16px' }}>
          <Grid container direction="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid item className={dialogDisplayFlex}>
              <img src={logo} className={dialogLogo} />
              <span style={{ width: '16px' }} />
              <Typography variant="body" className={dialogTitle}>
                {title}
              </Typography>
            </Grid>
            <Grid item className={dialogDisplayFlex}>
              <IconButton aria-label="close" className={dialogCloseButton} onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ padding: '0' }}>
          <div style={{ padding: 28 }}>
            <Typography style={{ lineHeight: '20px', fontSize: '14px', letter: '0.25px' }}>{description}</Typography>
          </div>
        </DialogContent>
        <div
          style={{
            display: 'flex',
            justifyContent: cancelLabel ? 'space-between' : 'flex-end',
            padding: '12px',
          }}
        >
          {cancelLabel && (
            <Button
              className="milo-btn-white"
              variant="contained"
              color="primary"
              onClick={this.handleClose}
              style={{
                width: '161px',
              }}
            >
              <Typography
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  color: '#616161',
                  fontWeight: '500',
                  lineHeight: '16px',
                  letterSpacing: '1.25px',
                }}
              >
                {cancelLabel}
              </Typography>
            </Button>
          )}
          <Button
            className="milo-btn-orange"
            variant="contained"
            color="primary"
            onClick={proceed}
            style={{
              minWidth: '161px',
              maxWidth: '200px'
            }}
          >
            {submitLabel}
          </Button>
        </div>
      </Dialog>
    );
  }
}
