// import { getChat } from 'appModule/chat/ducks/chats';

/* Reducer */
/*
**************************************************
  Action Types
**************************************************
*/
const prefix = 'PROVIDER/GLOBALSETTING';
const ONLINE = 'online';
const OFFLINE = 'offline';
const actionTypes = {
  CHANGE_CHATS_STATUS: Symbol(`${prefix}/CHANGE_CHATS_STATUS`),
  CHATS_ONLINE: Symbol(`${prefix}/CHATS_ONLINE`),
  CHATS_OFFLINE: Symbol(`${prefix}/CHATS_OFFLINE`),
  PANEL_OPEN: Symbol(`${prefix}/PANEL_OPEN`),
  PANEL_CLOSE: Symbol(`${prefix}/PANEL_CLOSE`),
  SIGN_OUT: Symbol(`${prefix}/SIGN_OUT`),
};

export { actionTypes, ONLINE, OFFLINE };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  changeChatsStatus: payload => ({ type: actionTypes.CHANGE_CHATS_STATUS, payload }),
  chatsOnline: payload => ({ type: actionTypes.CHATS_ONLINE, payload }),
  chatsOffline: payload => ({ type: actionTypes.CHATS_OFFLINE, payload }),
  panelOpen: payload => ({ type: actionTypes.PANEL_OPEN, payload }),
  panelClose: payload => ({ type: actionTypes.PANEL_CLOSE, payload }),
  signOut: payload => ({ type: actionTypes.SIGN_OUT, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/

// Note >> will replace this function
// const epics = [
//   (action$, store) =>
//     action$.ofType(actionTypes.CHANGE_CHATS_STATUS).map(({ payload }) => {
//       const { status } = payload;
//       // console.log(status, 'CHANGE_CHATS_STATUS');
//       const chatCore = getChat();
//       if (status === ONLINE) {
//         chatCore.online();
//       } else {
//         chatCore.offline();
//       }
//       return status === ONLINE ? actionCreators.chatsOnline() : actionCreators.chatsOffline();
//     }),
// ];

// export { epics };
/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  chatStatus: ONLINE,
  open: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.CHATS_ONLINE: {
      return { ...state, chatStatus: ONLINE };
    }
    case actionTypes.CHATS_OFFLINE: {
      return { ...state, chatStatus: OFFLINE };
    }
    case actionTypes.PANEL_OPEN: {
      return { ...state, open: true };
    }
    case actionTypes.PANEL_CLOSE: {
      return { ...state, open: false };
    }
    case actionTypes.SIGN_OUT: {
      return initState;
    }
    default: {
      return state;
    }
  }
};
