/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { ROLES, ORG_TYPES } from 'dataModule/constants';
import { SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import * as R from 'ramda';
import globalSidebar from 'utilsModule/globalSidebar';
import { Loading } from 'utilsModule/components';
import { switchProp, switchPath } from 'utilsModule';
import { withResource, resourceTypes, gettersOf } from 'dataModule/store/resources';
import { actionCreators as messageActionCreators } from 'appModule/message/ducks/message';
import commonUtil from 'utilsModule/common';
import { SelectProfile } from '../components';
import { getPubNubGrandToken, fetchChatList } from 'utilsModule/chatUtils';

@withResource([
  { resourceType: resourceTypes.CONFIGS, method: 'retrieveRoles', options: { runOnDidMount: true } },
  { resourceType: resourceTypes.USERS },
])
@connect(null, { notify: messageActionCreators.show })
@withRouter
class SelectProfileContainer extends PureComponent {
  static propTypes = {};
  componentDidMount() {
    this.mounted = true;
    const {
      location: { state: { profiles = [] } = {} },
    } = this.props;
    (R.length(profiles) === 1) && this.selectProfile(R.path([0, 'id'], profiles));
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  selectProfile = (profileId) => {
    const {
      location: { state: { profiles = [], entryLocation } = {} },
      actionCreators, notify, history,
    } = this.props;
    const profile = {
      role: switchProp('role', 'id', profileId, profiles),
      orgId: switchPath(['organization', 'id'], ['id'], profileId, profiles),
    };
    return new Promise((resolve, reject) => {
      actionCreators[resourceTypes.USERS].ajax({
        cargo: { method: 'selectProfile', input: { content: profile } },
        onSuccess: ({ data: selectProfileData }) => {
          if (this.mounted) {
            resolve(selectProfileData);
            const { sessionId, organization: { type: orgType } = {}, profile: { type: role } = {} } = R.path(['selectProfile'], selectProfileData);
            sessionStorage.setItem('session-token', sessionId);
            sessionStorage.setItem('user-role', role);
            sessionStorage.setItem('org-type', orgType);
            /* PATCH: Need to reset the status of retrieveCurrent
              so that PrivateRoute-WithLoading can prevent flowing down w/o fetching for currentUser.
              Scenario: Access a private route w/o session-token --> diverted to login, w/o reset --> diverted back to login again
            */
            actionCreators[resourceTypes.USERS].reset({ cargo: { method: 'retrieveCurrent' } });
            actionCreators[resourceTypes.USERS].ajax({
              cargo: { method: 'retrieveCurrent' },
              onSuccess: async ({ data: res }) => {
                const { retrieveCurrent: { uiPermissions = [] } } = res;
                const getSidebarAccess = uiPermissions.filter(i => i.category === 'SIDEBAR' && i.access === 'ALLOW');
                let renderMenu = [];
                switch (true) {
                  case role === ROLES.SUPERADMIN: renderMenu = globalSidebar.superAdminMenus; break;
                  case role === ROLES.ADMIN && orgType === ORG_TYPES.GROUP: renderMenu = globalSidebar.groupAdminMenus; break;
                  case role === ROLES.ADMIN && orgType === ORG_TYPES.CLUSTER: renderMenu = globalSidebar.clusterAdminMenus; break;
                  case role === ROLES.ADMIN && orgType === ORG_TYPES.HOSPITAL_CLINIC: renderMenu = globalSidebar.hospitalAdminMenus; break;
                  case [ROLES.DOCTOR, ROLES.NURSE, ROLES.CARE_MANAGER, ROLES.PHYSIOTHERAPIST, ROLES.DIETICIAN, ROLES.OTHERS].includes(role): renderMenu = globalSidebar.providerMenus; break;
                  default: renderMenu = [];
                }

                if([ROLES.DOCTOR, ROLES.NURSE, ROLES.CARE_MANAGER, ROLES.PHYSIOTHERAPIST, ROLES.DIETICIAN, ROLES.OTHERS].includes(role)) {
                  const { value: { key: chatKey } } = await fetchChatList(profileId);
                  const { value: { pnToken } } = await getPubNubGrandToken();
                  sessionStorage.setItem('publishKey', chatKey.publishKey);
                  sessionStorage.setItem('subscribeKey', chatKey.subscribeKey);
                  sessionStorage.setItem('pubnub-token', pnToken);
                }

                renderMenu = renderMenu.filter(i => getSidebarAccess.map(a => a.resource).includes(i.resource));
                if (renderMenu.length === 0 && role === ROLES.ADMIN) {
                  renderMenu.push({ path: 'acl' });
                }
                const route = R.pathOr('', [0, 'path'], renderMenu);
                const path = `/app/wsb/${route}`;
                // Temporary remove Organisation module for HOSPITAL/CLINIC ADMIN because creating of DEPARTMENT is disabled
                if (orgType === 'HOSPITAL_CLINIC' && role.includes('ADMIN')) {
                  history.push(entryLocation || path);
                } else if (orgType === 'HOSPITAL_CLINIC' && [
                  ROLES.DOCTOR,
                  ROLES.NURSE,
                  ROLES.CARE_MANAGER,
                  ROLES.PHYSIOTHERAPIST,
                  ROLES.DIETICIAN,
                  ROLES.OTHERS,
                ].includes(role)) {
                  history.push(entryLocation || path);
                } else if (role === ROLES.ADMIN) {
                  history.push(entryLocation || path);
                } else {
                  history.push(entryLocation || `/app/wsb/${role.includes('ADMIN') ? 'orgs' : 'programs'}`);
                }
                commonUtil.renameDocumentTitle(role, orgType);
              },
            });
          }
        },
        onFailure: ({ error }) => {
          if (this.mounted) {
            const reportedError = JSON.stringify(error, ['code', 'message']);
            reject(new SubmissionError({ _error: reportedError }));
            notify({ message: `Failed to select profile: ${reportedError}`, type: 'error' });
          }
        },
      });
    });
  }
  render() {
    const {
      data,
      location: { state: { profiles = [] } = {} },
    } = this.props;
    // process.env.DEBUG && console.log('%crender SelectProfileContainer', 'font-size: 12px; color: #00b3b3', this.props);
    if (data.status.loading || R.length(profiles) <= 1) return <Loading />;
    // Sanitize data
    const roles = gettersOf(resourceTypes.CONFIGS).getRoles()(data);
    if (!this.inited) {
      this.initialValues = {
        profile: {
          options: R.map(({
            id: profileId,
            role,
            organization: { name: orgName } = {},
          }) => ({
            id: profileId,
            value: profileId,
            label: `${orgName} - ${switchProp('label', 'value', role, roles) || role}`,
          }))(profiles),
        },
      };
      this.runtimeProps = {
        header: {
          banner: {
            title: 'Account Selection',
            subtitle: 'Please select a profile to login',
          },
        },
      };
      this.inited = true;
    }
    return <SelectProfile
      initialValues={this.initialValues}
      runtimeProps={this.runtimeProps}
      selectProfile={this.selectProfile}
      sendSelectProfile={this.sendSelectProfile} />;
  }
}
export default SelectProfileContainer;
