import React from 'react';
import { connect } from 'react-redux';
import Message from '../components/Message';
import { getters as messageGetters, actionCreators as messsageActionCreators } from '../ducks/message';

@connect(messageGetters.getState, messsageActionCreators)
class MessageContainer extends React.Component {
  render() {
    const {
      message, status, type, setStatus, customClass, customStyle = {}
    } = this.props;

    const handleClose = () => setStatus({ status: 'closed' });
    return <Message message={message} open={status === 'opened'} type={type} handleClose={handleClose} customClass={customClass} customStyle={customStyle} />;
  }
}

export default MessageContainer;
