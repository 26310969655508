import React from 'react';
import { Snackbar, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Close } from 'utilsModule/material-ui-icons';
import _ from 'lodash';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiSnackbar: {
      anchorOriginTopCenter: {
        zIndex: 99999,
      },
    },
  },
});

const styles = (theme) => ({
  info: {
    backgroundColor: '#ff5100',
    paddingRight: '40px',
  },
  warn: {
    backgroundColor: '#3333CC',
    paddingRight: '40px',
  },
  success: {
    backgroundColor: '#38b44a',
    maxWidth: '1081px',
    flexWrap: 'inherit',
    paddingRight: '40px',
  },
  expanded: {
    width: '1081px',
  },
  error: {
    backgroundColor: '#414ec1',
    maxWidth: '1081px',
    flexWrap: 'inherit',
    paddingRight: '40px',
  },
  required: {
    backgroundColor: '#D34431',
    maxWidth: '100%',
    flexWrap: 'inherit',
    paddingRight: '40px',
  },
  close: {
    width: '32px',
    height: '32px',
    position: 'absolute',
    top: 8,
    right: 5,
  },
});

export default withStyles(styles)(
  ({ message, open, type, handleClose, classes, customClass = [], customStyle = {} }) => (
    <ThemeProvider theme={theme}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={3000}
        onClose={(event, reason) => reason !== 'clickaway' && handleClose()}
        ContentProps={{
          'aria-describedby': 'message-id',
          className: [type, ...customClass].map((item) => classes[item]).join(' '),
        }}
        style={customStyle}
        message={
          <span id="message-id" style={{ whiteSpace: _.isEmpty(customClass) ? 'pre' : 'unset', fontWeight: 'normal' }}>
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={handleClose}>
            <Close />
          </IconButton>,
        ]}
      />
    </ThemeProvider>
  ),
);
