const CONTRACT_FREQUENCY = [
  { key: 'DAY', value: 'DAY', label: 'Day(s)' },
  { key: 'WEEK', value: 'WEEK', label: 'Week(s)' },
  { key: 'MONTH', value: 'MONTH', label: 'Month(s)' },
];

const SUSPENSION_REASON = [
  {
    key: 'VACATION',
    value: 'VACATION',
    label: 'Vacation',
  },
  {
    key: 'HOSPITALISATION',
    value: 'HOSPITALISATION',
    label: 'Hospitalisation',
  },
  {
    key: 'FAULTY_DEVICE',
    value: 'FAULTY_DEVICE',
    label: 'Faulty Device(s)',
  },
  {
    key: 'PENDING_OTHER_INSTRUCTIONS',
    value: 'PENDING_OTHER_INSTRUCTIONS',
    label: 'Pending Other Instructions',
  },
  {
    key: 'TREATMENT_ADJUSTMENT',
    value: 'TREATMENT_ADJUSTMENT',
    label: 'Treatment Adjustment',
  },
  {
    key: 'OTHERS',
    value: 'OTHERS',
    label: 'Others',
  }
];

const EXTENSION_REASON = [
  {
    key: 'EXTENSION_OF_TREATMENT',
    value: 'EXTENSION_OF_TREATMENT',
    label: 'Extension of Treatment',
  },
  {
    key: 'PENDING_DOCTORS_REVIEW',
    value: 'PENDING_DOCTORS_REVIEW',
    label: 'Pending Doctor\'s Review',
  },
  {
    key: 'DOCTORS_RECOMENDATION',
    value: 'DOCTORS_RECOMENDATION',
    label: 'Doctor\'s Recommendation',
  },
  {
    key: 'SUPPORT_RECOVERY',
    value: 'SUPPORT_RECOVERY',
    label: 'Support Recovery',
  },
  {
    key: 'OTHERS',
    value: 'OTHERS',
    label: 'Others',
  }
];

const CRUD_SEND_TO_PARTICIPANT_VIA = [
  { key: 'EMAIL', value: 'EMAIL', label: 'Email' },
  { key: 'SMS', value: 'SMS', label: 'SMS' },
];

const ERROR_MESSAGES = [
  {
    key: 'NO_SELECTED_DEVICES',
    title: 'Device Selection Required',
    description: 'You must select a medical device first to enable the continuous monitoring option.',
    submitLabel: 'Ok',
    cancelLabel: ''
  },
  {
    key: 'DEVICE_TYPE_ERROR_ADMIN_PROGRAM',
    title: 'Device Type Error!',
    description: 'No matching Charge Code is available for the selected device type/model and duration of this Programme. Please create Charge Code first.',
    submitLabel: 'Go to Charge Code',
    cancelLabel: 'Ok'
  },
  {
    key: 'DEVICE_TYPE_ERROR_ENROLL_PROGRAM',
    title: 'Device Type Error!',
    description: 'No matching Charge Code is available for the selected device type/model and duration of this Programme. Please reach out to your Administrator for assistance.',
    submitLabel: 'Ok',
    cancelLabel: ''
  },
  {
    key: 'POTENTIAL_HIGH_ALERTS_VOLUME',
    title: 'Potential High Alerts Volume',
    description: 'Since your alert frequency is set to less than the recommended 5 minutes, you may receive a high number of notifications for this vital sign. \n\nWould you like to proceed?',
    submitLabel: 'Yes, proceed',
    cancelLabel: 'No, return'
  },
  {
    key: 'DUPLICATE_VITALS',
    title: 'Continuous Monitoring Vitals with other device not supported',
    description: 'If you select a continuous monitoring programme, you cannot another set of device in the same programme. \n<br>\n \nIf you click ok, we will remove the additional set of vital group.\nIf you click cancel, we will change your continuous monitoring programme to spot monitoring.',
    submitLabel: 'Back',
    cancelLabel: ''
  }
];

export { CONTRACT_FREQUENCY, SUSPENSION_REASON, EXTENSION_REASON, CRUD_SEND_TO_PARTICIPANT_VIA, ERROR_MESSAGES};
