import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { withRouter } from 'react-router';

const mapState = state => ({
  user: R.path(['resources', 'users', 'retrieveCurrent'], state),
});

@withRouter
@connect(mapState)
class Notification extends React.Component {
  static displayName = 'ChatNotification';
  state = {
    key: null,
  };

  
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { key } = this.state;
    const newKey = R.path(['list', 'value', 'key'], nextProps);
    const user = R.path(['user'], nextProps);
    if (key === null && newKey && user) {
      this.setState({ key: newKey });
    }
  }

  render() {
    return <div style={{ display: 'none' }} />;
  }
}

export default Notification;
